import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(
          relativePath: { eq: "images/stripes/Rocky_Orange_Mountains.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Metadata
        title="Privacy"
        description="Hope Labs cares about your privacy. Our Privacy Policy is transparent, straight to the point and up-to-date."
      />
      <section role="main">
        <Row className={"no-margin"}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Rocky and orange mountain pass"
          />
        </Row>
        <Row className={"presentation"}>
          <Col className={"mb-2em"}>
            <h1 className={"mainTitle mt-2em"}>Hope Labs Privacy Policy</h1>
            <p className={"pureText"}>
              This website is operated by Hope Labs. The privacy of our users is
              extremely important to us and therefore we encourage all users to
              read this policy very carefully because it contains important
              information regarding:
            </p>
            <ul className={"unorderedSimple"}>
              <li>Who we are;</li>
              <br />
              <li>
                How and why we collect, store, use and share personal
                information;
              </li>
              <br />
              <li>Your rights in relation to your personal information;</li>
              <br />
              <li>
                How to contact us and supervisory authorities in the event that
                you have a complaint.
              </li>
              <br />
            </ul>

            <h4 className={"minorTitle mt-2em"}>Who we are</h4>
            <p className={"pureText"}>
              Hope Labs ('we' or 'us') collect, use and are responsible for
              storing certain personal information about you. When we do so, we
              are regulated under the General Data Protection Regulation (GDPR)
              which apply across the European Union (including the United
              Kingdom) and we are responsible as a 'controller' of that personal
              information for the purposes of those laws.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              Personal information we collect and use
            </h4>
            <p className={`${"pureText"} ${"underline"}`}>
              A. Personal information you provide to us
            </p>
            <p className={"pureText"}>
              We collect the following personal information that you provide to
              us:
            </p>
            <ul className={"unorderedSimple"}>
              <li>Name</li>
              <br />
              <li>Email</li>
              <br />
            </ul>
            <p className={"pureText"}>
              Some examples of when we collect this information include:
            </p>
            <ul className={"unorderedSimple"}>
              <li>Subscribing to our Newsletter</li>
              <br />
              <li>Making Inquiries</li>
              <br />
              <li>Making Donations</li>
              <br />
              <li>Sending us your feedback</li>
              <br />
            </ul>
            <p className={`${"pureText"} ${"underline"}`}>
              B. Personal information you provide about third parties
            </p>
            <p className={"pureText"}>
              If you give us information about another person, you confirm that
              the other person has appointed you to act on their behalf and
              agreed that you:
            </p>
            <ul className={"unorderedSimple"}>
              <li>
                shall consent on their behalf to the processing of their
                personal data;
              </li>
              <br />
              <li>
                shall receive any data protection notices on their behalf; and
              </li>
              <br />
              <li>
                shall consent on their behalf to the transfer of their personal
                data abroad.
              </li>
              <br />
            </ul>
            <p className={`${"pureText"} ${"underline"}`}>
              C. Monitoring and recording communications
            </p>
            <p className={"pureText"}>
              We may monitor communications such as emails and telephone calls
              for the following purposes:
            </p>
            <p>
              improving our standards, training, fraud prevention, compliance,
              etc.
            </p>
            <p className={`${"pureText"} ${"underline"}`}>
              {" "}
              d. Cookies and similar technologies
            </p>
            <p className={"pureText"}>
              A cookie is a small text file which is placed onto your computer
              or electronic device when you access our website. Similar
              technologies include web beacons, action tags, local shared
              objects ('flash cookies') and single-pixel gifs. Such technologies
              can be used to track users' actions and activities, and to store
              information about them. We use these cookies and/or similar
              technologies on this website.
            </p>
            <p className={"pureText"}>
              In addition, it should be noted that in some cases our cookies or
              similar technologies may be owned and controlled by third parties
              who will also collect personal information about you.
            </p>
            <p className={"pureText"}>
              The following examples illustrate how we may use cookies to
              monitor and/or collect the following information:
            </p>
            <ul className={"unorderedSimple"}>
              <li>Storing language preferences</li>
              <br />
              <li>Presenting more relevant articles</li>
              <br />
            </ul>
            <p className={"pureText"}>
              This information helps us to build a profile of our users. Some of
              this information may be aggregated or statistical, which means
              that we will not be able to identify you individually.
            </p>
            <p className={"pureText"}>
              On the first occasion that you use our site we will ask whether
              you consent to our use of cookies. If you do not, cookies will not
              be used. Thereafter you can opt-out of using cookies at any time
              or you can set your browser not to accept cookies and the websites
              below tell you how to remove cookies from your browser. However,
              some of our website features may not function as a result.
            </p>
            <p className={"pureText"}>
              For further information on our use of cookies, please see our
              Website cookie policy.
            </p>
            <p className={"pureText"}>
              For further information on cookies generally visit
              <a
                href="https://www.aboutcookies.org/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Aboutcookies.org
              </a>{" "}
              or{" "}
              <a
                href="https://www.allaboutcookies.org/"
                target="_blank"
                rel="noreferrer"
              >
                Allaboutcookies.org
              </a>
              .
            </p>

            <h4 className={"minorTitle mt-2em"}>
              How we use your personal information
            </h4>
            <p className={"pureText"}>
              We collect information about our users for the following purposes:
            </p>
            <ul className={"unorderedSimple"}>
              <li>To identify the users and manage their accounts</li>
              <br />
              <li>To share our newsletter</li>
              <br />
              <li>To ask for feedback on our service</li>
              <br />
            </ul>

            <h4 className={"minorTitle mt-2em"}>
              Who your information might be shared with
            </h4>
            <p className={"pureText"}>
              We may share your information with law enforcement agencies in
              connection with any investigation to help prevent unlawful
              activity.
            </p>
            <p className={"pureText"}>
              We will not share you personal information with any other 3rd
              parties.
            </p>

            <h4 className={"minorTitle mt-2em"}>Marketing</h4>
            <p className={"pureText"}>
              We would like to send you information about products, services,
              offers, competitions and our business which may be of interest to
              you. Such information could be sent by post, email, telephone,
              text message or automated call.
            </p>
            <p className={"pureText"}>
              We will ask whether you would like us to send you marketing
              messages on the first occasion that you provide any relevant
              contact information (i.e. on purchase, signing up to a newsletter,
              entering a competition etc). If you do opt in to receive such
              marketing from us you can opt out at any time (see 'What rights do
              you have?' below for further information). If you have any queries
              about how to opt out, or if you are receiving messages you do not
              want you can contact us using the details provided below.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              Required personal information
            </h4>
            <p className={"pureText"}>
              No personal information has to be provided by you to us at any
              time.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              How long your personal information will be kept
            </h4>
            <p className={"pureText"}>
              We will hold your personal information for the following periods:
            </p>
            <ul className={"unorderedSimple"}>
              <li>
                Name and Email: As long as you are interested in receiving our
                newsletter.
              </li>
              <br />
            </ul>
            <p className={"pureText"}>
              These periods are no longer than necessary in each case.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              Reasons we can collect and use your personal information
            </h4>
            <p className={"pureText"}>
              We rely on the following as the lawful basis on which we collect
              and use your personal information:
            </p>
            <ul className={"unorderedSimple"}>
              <li>Consent</li>
              <br />
              <li>Contract</li>
              <br />
              <li>Legal obligation</li>
              <br />
            </ul>

            <h4 className={"minorTitle mt-2em"}>
              Keeping your information secure
            </h4>
            <p className={"pureText"}>
              We have appropriate security measures in place to prevent personal
              information from being accidentally lost, or used or accessed in
              an unauthorised way. We limit access to your personal information
              to those who have a genuine business need to know it. Those
              processing your information will do so only in an authorised
              manner and are subject to a duty of confidentiality.{" "}
            </p>
            <p className={"pureText"}>
              We will also use technological and organisation measures to keep
              your information secure. These measures may include the following
              examples:
            </p>
            <p>
              Securing accounts with passwords, using secure servers and SSL
              encryption, etc.
            </p>
            <p className={"pureText"}>
              We also have procedures in place to deal with any suspected data
              security breach. We will notify you and any applicable regulator
              of a suspected data security breach where we are legally required
              to do so.
            </p>
            <p className={"pureText"}>
              Indeed, while we will use all reasonable efforts to secure your
              personal data, in using the site you acknowledge that the use of
              the internet is not entirely secure and for this reason we cannot
              guarantee the security or integrity of any personal data that are
              transferred from you or to you via the internet. If you have any
              particular concerns about your information, please contact us
              using the details below.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              Transfer of information outside the EEA
            </h4>
            <p className={"pureText"}>
              We will not transfer your personal information outside of the EEA
              at any time.
            </p>

            <h4 className={"minorTitle mt-2em"}>
              Children and the validity of consent
            </h4>
            <p className={"pureText"}>
              Where we obtain consent from any user we will take reasonable
              steps to ascertain whether the user is over 13 years of age and
              whether the child is sufficiently informed to give valid consent.
              If the user is not, parental consent will be required to provide
              consent for the processing of any personal information.
            </p>

            <h4 className={"minorTitle mt-2em"}>What rights do you have?</h4>
            <p className={"pureText"}>
              Under the General Data Protection Regulation you have a number of
              important rights free of charge. In summary, those include rights
              to:
            </p>
            <ul className={"unorderedSimple"}>
              <li>
                Fair processing of information and transparency over how we use
                your use personal information.
              </li>
              <br />
              <li>
                Access to your personal information and to certain other
                supplementary information that this Privacy Notice is already
                designed to address.
              </li>
              <br />
              <li>
                Require us to correct any mistakes in your information which we
                hold.
              </li>
              <br />
              <li>
                Require the erasure of personal information concerning you in
                certain situations.
              </li>
              <br />
              <li>
                Receive the personal information concerning you which you have
                provided to us, in a structured, commonly used and
                machine-readable format and have the right to transmit those
                data to a third party in certain situations.
              </li>
              <br />
              <li>
                Object at any time to processing of personal information
                concerning you for direct marketing.
              </li>
              <br />
              <li>
                Object to decisions being taken by automated means which produce
                legal effects concerning you or similarly significantly affect
                you.
              </li>
              <br />
              <li>
                Object in certain other situations to our continued processing
                of your personal information.
              </li>
              <br />
              <li>
                Otherwise restrict our processing of your personal information
                in certain circumstances.
              </li>
              <br />
              <li>
                Claim compensation for damages caused by our breach of any data
                protection laws.
              </li>
              <br />
            </ul>
            <p className={"pureText"}>
              For further information on each of those rights, including the
              circumstances in which they apply, see the Guidance from the UK
              Information Commissioner's Office (ICO) on individual's rights
              under the{" "}
              <a
                href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
                target="_blank"
                rel="noreferrer"
              >
                General Data Protection Regulations
              </a>
              .
            </p>
            <p className={"pureText"}>
              If you would like to exercise any of these rights please:
            </p>
            <ul className={"unorderedSimple"}>
              <li>Email, call or write to us.</li>
              <br />
              <li>Let us have enough information to identify you.</li>
              <br />
              <li>
                Let us have proof of your identity (a copy of your driving
                license, passport or a recent credit card/utility bill).
              </li>
              <br />
              <li>
                Let us know the information to which your request relates.
              </li>
              <br />
            </ul>

            <h4 className={"minorTitle mt-2em"}>
              Changes to the privacy policy
            </h4>
            <p className={"pureText"}>
              This privacy policy was published on 3rd February 2021 and last
              updated on 6th April 2021.
            </p>
            <p className={"pureText"}>
              We may change this privacy policy from time to time. You should
              check this policy occasionally to ensure you are aware of the most
              recent version that will apply each time you access this website.
              We will also attempt to notify users of any changes by:
            </p>
            <p>
              - By email, if the user is also subscribed.- Using a banner on our
              privacy page.
            </p>

            <h4 className={"minorTitle"}>Contacting us</h4>
            <p className={"pureText"}>
              Our data protection officer is Mr. MARCO GHILARDI. If you have any
              questions about this policy or the information we hold about you,
              please contact us by:
            </p>
            <p className={"pureText"}>
              <span className={"minorTitle"}>e-mail:</span>{" "}
              <a href="mailto:hopelabs@quasiscience.com">
                hopelabs@quasiscience.com
              </a>
            </p>
            {/* <p >post:</p>
                            <p>To be decided,Buckingham,MK18 XXX,UK</p> */}
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default About;
